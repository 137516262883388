import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0 justify-content-center ml-2 mr-2" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SalesHeaderButtons = _resolveComponent("SalesHeaderButtons")!
  const _component_SalesOppsDataTableWrapper = _resolveComponent("SalesOppsDataTableWrapper")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SalesOppDialog = _resolveComponent("SalesOppDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_SalesHeaderButtons, {
            title: "Opportunity",
            "new-button-text": "New Opportunity",
            dataTestButtonTag: "newSalesOpps",
            "print-button-text": "Print/Export",
            ShowTableViewButtons: true,
            showClearFilterButton: true,
            lastUpdated: _ctx.lastUpdated,
            onNewButtonClicked: _ctx.handleSalesOpp,
            onIsResultView: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isResultViewChange($event))),
            onPrintButtonClicked: _ctx.printExportData,
            onClearFilters: _ctx.clearFilters
          }, null, 8, ["lastUpdated", "onNewButtonClicked", "onPrintButtonClicked", "onClearFilters"]),
          _createVNode(_component_SalesOppsDataTableWrapper, {
            isResultView: _ctx.isResultView,
            ref: "salesOppsTable",
            onLastUpdated: _ctx.isDataUpdated,
            maxExportRows: 1000,
            printExportData: _ctx.printExportData,
            clearFilters: _ctx.clearFilters
          }, null, 8, ["isResultView", "onLastUpdated", "printExportData", "clearFilters"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_SalesOppDialog, {
      show: this.showDialog,
      isEditing: this.isEditing,
      saleOpp: this.saleOpp,
      onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hideDialog(_ctx.hidden))),
      onOnSave: _ctx.refreshData,
      opportunity: this.saleOpp
    }, null, 8, ["show", "isEditing", "saleOpp", "onOnSave", "opportunity"])
  ]))
}