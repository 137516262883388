
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import SalesOppDialog from "@/components/Sales/SalesOppDialog.vue";
import SaleOpp from "@/types/saleOpps";
import { mapState, mapActions, mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";
import MultiselectWithButton from "@/components/UI/MultiselectWithButton.vue";
import CustomerService from "@/services/CustomerService";
import SaleOppsService from "@/services/SaleOppsService";
import KanbanView from "./KanbanView.vue";
import Utils from "@/utility/utils";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "Sales",
  components: {
    LoadingSpinner,
    InputText,
    Button,
    Calendar,
    DataTable,
    Column,
    SalesOppDialog,
    KanbanView,
    MultiselectWithButton,
  },
  created() {
    if (this.customerIndex !== undefined) {
      this.filters.cust_name.value = [this.getCurrentCustomer.name];
      this.selectedFilterCusts = [this.getCurrentCustomer];

      this.storeSortFilters();
    } else {
      this.clearCustomerSearch();
    }

    this.isLoadingSalesOpps = true;

    this.applyStoredSortFilters();
    this.fetchSaleOpps(false);

    if ((this.getSaleOpps?.length || 0) >= this.rows) {
      this.first = this.getCurrPage * this.rows;
    }
    if (this.isSaleOpportunityAvailable) {
      this.loadingControls = true;
      const control = {
        procedure: "SALEOPP.CONTROL",
        filename: "CONTROL",
        id: "SALEOPP",
      } as const;
      this.fetchControl(control).finally(() => {
        this.loadingControls = false;
      });
    }

    if (this.customerIndex) {
      this.$emit(
        "update-badge",
        "saleopp_ids",
        this.getSalesOppsIds(this.customerIndex).length
          ? this.getSalesOppsIds(this.customerIndex).length.toString()
          : "",
      );
    }
  },

  updated() {
    if (this.salesOpps.length === 0) {
      this.$emit("update-badge", "saleopp_ids", 0);
    }
  },

  computed: {
    ...mapState(["control"]),
    ...mapGetters({
      getSaleOpps: "saleOpps/getSaleOpps",
      getRangeEnd: "saleOpps/getRangeEnd",
      getCurrPage: "saleOpps/getCurrPage",
      getFirstRow: "saleOpps/getFirstRow",
      getUserId: "session/getUserId",
      isSaleOpportunityAvailable: "control/isSaleOpportunityAvailable",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
      getSalesOppsIds: "customerInquiry/getSalesOppsIds",
      getSalesOppsSortFilters: "filters/getSalesOppsSortFilters",
      getUsers: "users/getUsers",
      filterInactiveCustomers: "mrkControl/filterInactiveCustomers",
    }),
  },
  mounted() {
    this.first = this.getFirstRow;
  },
  unmounted() {
    this.clearAndResetOpps();
  },
  props: {
    customerIndex: Number,
    currentView: String,
    isResultView: {
      type: Boolean,
      default: true,
    },
    maxExportRows: {
      type: Number,
      required: true,
    },
  },
  emits: ["update-badge", "lastUpdated", "customer-view-click"],
  data() {
    return {
      salesOpps: [] as Array<SaleOpp>,
      saleOpp: null as unknown,
      selectedRow: null,
      selectedSalesOppsId: "",
      estCloseDateStart: "",
      estCloseDateEnd: "",
      assigneeIdItems: [],
      statusOptions: [],
      first: 0,
      rows: 10,
      page: 1,
      rangeStart: 1,
      rangeEnd: 100,
      id: "",
      sortOrder: -1,
      sortField: "formatted_est_close",
      filters: {
        id: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        cust_name: {
          value: [] as any,
          matchMode: FilterMatchMode.IN,
        },
        assigned_to: {
          value: [] as any[],
          matchMode: FilterMatchMode.IN,
        },
        status: {
          value: [] as any[],
          matchMode: FilterMatchMode.IN,
        },
        formatted_est_close: {
          value: [] as any[],
          matchMode: FilterMatchMode.BETWEEN,
        },
      },
      allExportColumns: [
        { field: "id", header: "ID", input: true },
        { field: "status", header: "Status", input: true },
        { field: "cust_name", header: "Customer", input: true },
        { field: "assigned_to", header: "Assigned To", input: true },
        { field: "est_close", header: "Est Close", input: true },
        { field: "amount", header: "Amount", input: true },
        { field: "prob_pct", header: "Prob %", input: true },
      ],
      dynamicColumns: [
        { field: "id", header: "ID", input: true },
        { field: "status", header: "Status", input: true },
        { field: "cust_name", header: "Customer", input: true },
        { field: "assigned_to", header: "Assigned To", input: true },
        { field: "est_close", header: "Est Close", input: true },
        { field: "amount", header: "Amount", input: true },
        { field: "prob_pct", header: "Prob %", input: true },
      ],
      isLoadingSalesOpps: false,
      loadingControls: false,
      showDialog: false as boolean,
      isEditing: false as boolean,
      selectedFilterAssignees: [] as any[],
      selectedFilterCusts: [] as any[],
      searchedFilterCusts: [] as any[],
      selectedFilterStatus: [] as any[],
      selectedFilterEstClose: undefined as any,
      selectedFilterId: "",
      custService: new CustomerService(),
      saleOppService: new SaleOppsService(process.env.VUE_APP_ABSTRACTION_API),
      totalRecords: 0,
      formatedLists: [] as Array<any>,
      isProduction: this.isProduction,
    };
  },
  watch: {
    getCurrentCustomer() {
      if (
        this.getCurrentCustTabIndex == this.customerIndex &&
        this.getCurrentCustomer
      ) {
        this.filters.cust_name.value = [this.getCurrentCustomer.name];
        this.selectedFilterCusts = [this.getCurrentCustomer];
        this.filterCust();
      }
    },
    salesOpps() {
      if (!this.isResultView) {
        this.formatBoardList();
      }
    },
    isResultView() {
      this.clearAllFilters();
      if (!this.isResultView) {
        this.formatBoardList();
      }
      this.resetRange();
      this.fetchSaleOpps(false);
    },
  },
  inject: ["isProduction"],
  methods: {
    ...mapActions({
      fetchSaleOppsRecords: "saleOpps/fetchSaleOpps",
      setFirstRow: "saleOpps/setFirstRow",
      fetchControl: "control/fetchControl",
      addNotification: "notification/add",
      clearAndResetOpps: "saleOpps/clearAndResetOpps",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
      setSalesOppsSortFilters: "filters/setSalesOppsSortFilters",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
      addOpenedSalesOppCustomerInquiry:
        "customerInquiry/addOpenedSalesOppCustomerInquiry",
    }),
    async printTable() {
      //block a print with no criteria applied
      if (this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > this.maxExportRows) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        this.rangeStart = 1;
        this.rangeEnd = this.maxExportRows;
        await this.fetchSaleOpps(false);
        this.setPrintData(this.salesOpps);
        this.setPrintDefaultColumns(this.dynamicColumns);
        this.setPrintAvaialbleColumns(this.allExportColumns);

        window.open("/printable-view?print=1&showSelection=1", "_blank");
      }
    },
    openSalesDialog(read: boolean) {
      this.showDialog = true;
      if (!read) {
        this.saleOpp = null;
      }

      this.isEditing = read;
    },
    resetRange() {
      this.rangeStart = 1;
      this.rangeEnd = 100;
    },
    getFilteredResults() {
      this.resetRange();
      this.fetchSaleOpps(false);
    },
    filterCust() {
      this.filters.cust_name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });
      this.searchedFilterCusts = [...this.selectedFilterCusts];
      this.resetRange();
      this.fetchSaleOpps(false);
    },
    handleCustomerSelectFilter: _debounce(async function (event) {
      // @ts-expect-error becuas of exploit this
      const customers = await this.custService.getAllCustomers({
        selection: event.value,
        // @ts-expect-error becuas of exploit this
        activeOnly: this.filterInactiveCustomers,
      });
      // @ts-expect-error becuas of exploit this
      this.searchedFilterCusts = _uniq([
        // @ts-expect-error becuas of exploit this
        ...customers.cust_items.sort((a, b) => a.name.localeCompare(b.name)),
        // @ts-expect-error becuas of exploit this
        ...this.selectedFilterCusts,
      ]);
    }, 1000),
    getCustomersFilter() {
      return this.selectedFilterCusts.map((cust) => {
        return cust.cust_id;
      });
    },
    showRow(event: any) {
      this.saleOpp = event;
      this.openSalesDialog(true);
      this.id = event.id;
    },
    rowClick(event: any) {
      let itemToEdit = event;
      if (event.data) {
        itemToEdit = event.data;
      }

      itemToEdit["saleType"] = "opportunities";
      itemToEdit["oldRecord"] = cloneDeep(itemToEdit);

      if (this.$router.currentRoute.value.path.includes("customers")) {
        this.addOpenedSalesOppCustomerInquiry({ ...itemToEdit });
        this.$router.push(
          `/customers/${itemToEdit.cust}/sales/opportunities/${itemToEdit.id}`,
        );
      } else {
        this.addOpenedSalesOrder({ ...itemToEdit });
        this.$router.push(`/sales/opportunities/${itemToEdit.id}`);
      }
    },
    hideDialog(hidden: boolean) {
      this.showDialog = hidden;
      this.saleOpp = null;
      this.isEditing = false;
    },
    pageClick(event: any) {
      this.setFirstRow(event.first);
      if (
        (event.page == event.pageCount ||
          event.page == event.pageCount - 1 ||
          event.page == event.pageCount - 2) &&
        this.rangeEnd < this.totalRecords
      ) {
        this.rangeStart = this.rangeEnd + 1;
        this.rangeEnd = this.rangeEnd + 100;
        this.fetchSaleOpps(true);
      }
    },
    sortData(event: any) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.resetRange();
      this.fetchSaleOpps(false);
    },
    clearCustomerSearch() {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
      this.resetRange();
      this.fetchSaleOpps(false);
    },
    clearAllFilters() {
      if (this.customerIndex !== undefined) {
        this.filters.cust_name.value = [this.getCurrentCustomer.name];
        this.selectedFilterCusts = [this.getCurrentCustomer];
      } else {
        this.filters.cust_name.value = [];
        this.selectedFilterCusts = [];
      }
      this.selectedFilterId = "";
      this.filters.id.value = "";
      this.selectedFilterStatus = [];
      this.filters.assigned_to.value = [];
      this.selectedFilterAssignees = [];
      this.estCloseDateStart = "";
      this.estCloseDateEnd = "";
      this.selectedFilterEstClose = null;
      this.filters.formatted_est_close.value = [];
      this.resetRange();
    },
    clearAllFiltersAndFetch() {
      this.clearAllFilters();
      this.fetchSaleOpps(false);
    },
    clearIdSearch() {
      this.selectedFilterId = "";
      this.filters.id.value = "";
      this.resetRange();
      this.fetchSaleOpps(false);
    },
    clearStatusFilter() {
      this.selectedFilterStatus = [];
      this.resetRange();
      this.fetchSaleOpps(false);
    },
    clearAssignedToFilter() {
      this.filters.assigned_to.value = [];
      this.selectedFilterAssignees = [];
      this.resetRange();
      this.fetchSaleOpps(false);
    },
    clearDateFilter() {
      this.estCloseDateStart = "";
      this.estCloseDateEnd = "";
      this.selectedFilterEstClose = null;
      this.filters.formatted_est_close.value = [];
      this.resetRange();
      this.fetchSaleOpps(false);
    },
    getAssigneeFilter() {
      let assignees = [];
      if (this.filters.assigned_to.value) {
        assignees = Object.values(this.filters.assigned_to.value);
      }
      return assignees;
    },
    async fetchSaleOpps(isAddingSaleOpps: boolean) {
      // Sort logic
      let sortBy = "";
      let sortOrderRecordsBy = "";

      switch (this.sortField) {
        case "formatted_est_close":
          sortBy = "est.close";
          break;
        default:
          sortBy = this.sortField.replaceAll("_", ".");
          break;
      }

      if (this.sortOrder === -1) {
        sortOrderRecordsBy = "DEC";
      }

      if (this.showDialog == true) {
        this.showDialog = false;
      }
      this.filters.id.value = this.selectedFilterId;
      this.isLoadingSalesOpps = true;
      this.filters.assigned_to.value = [...this.selectedFilterAssignees];
      this.filters.status.value = [...this.selectedFilterStatus];
      this.filters.cust_name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });

      if (this.selectedFilterEstClose) {
        if (this.selectedFilterEstClose[0] != null)
          this.estCloseDateStart = this.selectedFilterEstClose[0];
        if (this.selectedFilterEstClose[1] != null)
          this.estCloseDateEnd = this.selectedFilterEstClose[1];

        this.estCloseDateStart = this.formatStringDate(this.estCloseDateStart);
        this.estCloseDateEnd = this.formatStringDate(this.estCloseDateEnd);

        this.filters.formatted_est_close.value = this.selectedFilterEstClose;
      }

      this.filters.formatted_est_close.value = [];
      if (this.selectedFilterEstClose) {
        if (this.selectedFilterEstClose[0] != null)
          this.estCloseDateStart = this.selectedFilterEstClose[0];
        if (this.selectedFilterEstClose[1] != null)
          this.estCloseDateEnd = this.selectedFilterEstClose[1];

        this.estCloseDateStart = this.formatStringDate(this.estCloseDateStart);
        this.estCloseDateEnd = this.formatStringDate(this.estCloseDateEnd);

        if (this.estCloseDateStart)
          this.filters.formatted_est_close.value.push(
            this.selectedFilterEstClose[0],
          );
        if (this.estCloseDateEnd)
          this.filters.formatted_est_close.value.push(
            this.selectedFilterEstClose[1],
          );
      }

      this.storeSortFilters();
      try {
        const resp = await this.fetchSaleOppsRecords({
          ids: this.filters.id.value,
          custs: this.getCustomersFilter(),
          assignees: this.getAssigneeFilter(),
          status: this.selectedFilterStatus,
          isAddingSaleOpps: isAddingSaleOpps,
          sortBy: sortBy,
          correls: "cust_name",
          sortOrder: sortOrderRecordsBy,
          dateStart: this.estCloseDateStart,
          dateEnd: this.estCloseDateEnd || this.estCloseDateStart,
          rangeStart: this.rangeStart,
          rangeEnd: this.rangeEnd,
          disableSortedSample: "Y",
        });

        isAddingSaleOpps
          ? this.salesOpps.push(...resp.data)
          : (this.salesOpps = resp.data);

        this.totalRecords = resp.totalItemsFound;

        this.salesOpps = this.salesOpps.map((opp: any) => {
          opp["formatted_est_close"] = new Date(opp.est_close);
          return opp;
        });
        this.$emit("update-badge", "saleopp_ids", this.salesOpps.length);
      } finally {
        this.$emit("lastUpdated", Date.now());
        this.isLoadingSalesOpps = false;
        if (!isAddingSaleOpps) {
          this.first = 0;
          this.page = 1; //might be unecessary
        }
      }
    },
    formatStringDate(dueDate: string) {
      if (!dueDate || dueDate == null) return "";
      const reformatedDueDate = Utils.formatDate(dueDate);

      return reformatedDueDate;
    },
    formatCurrency: (value: string) => {
      if (value) {
        return parseInt(value, 10).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      } else {
        return "";
      }
    },
    handleFilter(event: any) {
      this.$emit(
        "update-badge",
        "saleopp_ids",
        event.filteredValue.length.toString(),
      );
    },
    formatBoardList() {
      let controlStatusList: Array<any> = [];
      controlStatusList = JSON.parse(
        JSON.stringify(this.control.saleOpportunity.status_items),
      );
      controlStatusList.unshift({
        status: "Unassigned",
      });
      controlStatusList.forEach((element) => (element.items = []));

      this.salesOpps.forEach((item: any) => {
        const statusIndex = controlStatusList.findIndex(
          (element) => element.status === item.status,
        );
        if (statusIndex >= 0) {
          controlStatusList[statusIndex].items.push(item);
        } else {
          controlStatusList[0].items.push(item);
        }
      });
      this.formatedLists = controlStatusList;
    },
    onStatusChange(event: any) {
      let quoteWithNewStatus = JSON.parse(
        JSON.stringify(event.item.added.element),
      );
      quoteWithNewStatus.status = event.status;
      this.saleOppService.updateSaleOpp(
        quoteWithNewStatus,
        event.item.added.element,
      );
    },
    storeSortFilters() {
      const filterCusts = {
        value: this.selectedFilterCusts,
      };
      const sortFiltersToStore = Utils.getSortFiltersToStore({
        filters: { ...this.filters, cust_name: filterCusts },
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      });
      this.setSalesOppsSortFilters(sortFiltersToStore);
    },
    applyStoredSortFilters() {
      const filters = this.getSalesOppsSortFilters.filters;
      for (const filter in filters) {
        switch (filter) {
          case "id":
            this.selectedFilterId = filters[filter];
            break;
          case "cust_name":
            this.selectedFilterCusts = filters[filter];
            this.handleCustomerSelectFilter(filters[filter]);
            break;
          case "assigned_to":
            this.selectedFilterAssignees = filters[filter];
            break;
          case "status":
            this.selectedFilterStatus = filters[filter];
            break;
          case "formatted_est_close":
            this.selectedFilterEstClose = filters[filter];
            break;
        }
      }
      this.sortField = this.getSalesOppsSortFilters.sort.sortField;
      this.sortOrder = this.getSalesOppsSortFilters.sort.sortOrder;
    },
  },
});
