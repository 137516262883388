import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "mt-2" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiselectWithButton = _resolveComponent("MultiselectWithButton")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_KanbanView = _resolveComponent("KanbanView")!
  const _component_SalesOppDialog = _resolveComponent("SalesOppDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LoadingSpinner, { loading: _ctx.isLoadingSalesOpps }, null, 8, ["loading"]),
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.isLoadingSalesOpps)
          ? (_openBlock(), _createBlock(_component_DataTable, {
              key: 0,
              value: _ctx.salesOpps,
              breakpoint: "1267px",
              dataKey: "id",
              rows: _ctx.rows,
              first: _ctx.first,
              "onUpdate:first": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.first) = $event)),
              selection: _ctx.selectedRow,
              "onUpdate:selection": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.selectedRow) = $event)),
              selectionMode: "single",
              paginator: true,
              sortField: _ctx.sortField,
              sortOrder: _ctx.sortOrder,
              responsiveLayout: "stack",
              rowsPerPageOptions: [10, 25, 50],
              onPage: _ctx.pageClick,
              onSort: _ctx.sortData,
              onRowClick: _ctx.rowClick,
              filters: _ctx.filters,
              "onUpdate:filters": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.filters) = $event)),
              onFilter: _ctx.handleFilter,
              filterDisplay: "menu",
              class: _normalizeClass(["p-datatable-sm tablehead-br-no-color", {
          'datatable-only-header column-header-separator': !_ctx.isResultView,
        }])
            }, {
              empty: _withCtx(() => [
                _createTextVNode(" No records found ")
              ]),
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "id",
                  header: "ID",
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  sortable: _ctx.isResultView
                }, {
                  filter: _withCtx(() => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: _ctx.selectedFilterId,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilterId) = $event)),
                      class: "p-column-filter",
                      placeholder: "Search by Id"
                    }, null, 8, ["modelValue"])
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Clear",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearIdSearch()))
                    })
                  ]),
                  filterapply: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Apply",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getFilteredResults()))
                    })
                  ]),
                  _: 1
                }, 8, ["sortable"]),
                (_ctx.customerIndex == null)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      key: 0,
                      field: "cust_name",
                      header: "Customer",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      sortable: _ctx.isResultView
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_MultiselectWithButton, {
                          value: _ctx.selectedFilterCusts,
                          options: _ctx.searchedFilterCusts,
                          filterFields: ['name', 'cust_id'],
                          filter: "",
                          optionLabel: "name",
                          placeHolder: "Enter Customer ID or Name",
                          maxSelectedLabels: 2,
                          onFilter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCustomerSelectFilter($event))),
                          style: { width: '200px' },
                          btnLabel: "Search",
                          onBtnCallback: _cache[4] || (_cache[4] = ($event: any) => (_ctx.filterCust())),
                          onUpdateFilterValue: _cache[5] || (_cache[5] = 
                (filterValue) => (_ctx.selectedFilterCusts = filterValue)
              )
                        }, null, 8, ["value", "options"])
                      ]),
                      filterclear: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Clear",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clearCustomerSearch()))
                        })
                      ]),
                      filterapply: _withCtx(() => [
                        _createVNode(_component_Button, {
                          type: "button",
                          label: "Search",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.filterCust()))
                        })
                      ]),
                      _: 1
                    }, 8, ["sortable"]))
                  : _createCommentVNode("", true),
                (_ctx.isResultView)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      key: 1,
                      field: "prob_pct",
                      header: "Prob %",
                      sortable: "",
                      bodyStyle: "text-align: right; padding-right: 1rem"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_Column, {
                  field: "status",
                  header: "Status",
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  sortable: _ctx.isResultView
                }, {
                  filter: _withCtx(() => [
                    _createVNode(_component_MultiselectWithButton, {
                      value: _ctx.selectedFilterStatus,
                      options: _ctx.control.saleOpportunity.status_items,
                      optionLabel: "status",
                      optionValue: "status",
                      dataKey: "status",
                      placeHolder: "Filter By Status",
                      filter: true,
                      filterMatchMode: "startsWith",
                      style: { width: '200px' },
                      btnLabel: "Apply",
                      onBtnCallback: _cache[8] || (_cache[8] = ($event: any) => (_ctx.getFilteredResults())),
                      onUpdateFilterValue: _cache[9] || (_cache[9] = 
                (filterValue) => (_ctx.selectedFilterStatus = filterValue)
              )
                    }, null, 8, ["value", "options"])
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Clear",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.clearStatusFilter()))
                    })
                  ]),
                  filterapply: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Apply",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.getFilteredResults()))
                    })
                  ]),
                  body: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", {
                        class: _normalizeClass({ 'status-column': slotProps.data.status })
                      }, _toDisplayString(slotProps.data.status), 3)
                    ])
                  ]),
                  _: 1
                }, 8, ["sortable"]),
                _createVNode(_component_Column, {
                  field: "assigned_to",
                  header: "Assigned To",
                  showAddButton: false,
                  showFilterMatchModes: false,
                  showFilterOperator: false,
                  sortable: _ctx.isResultView
                }, {
                  filter: _withCtx(() => [
                    _createVNode(_component_MultiselectWithButton, {
                      value: _ctx.selectedFilterAssignees,
                      options: _ctx.getUsers,
                      optionLabel: "user_name",
                      optionValue: "user_id",
                      dataKey: "user_id",
                      placeHolder: "Filter By Assignee",
                      filter: true,
                      filterMatchMode: "startsWith",
                      style: { width: '200px' },
                      btnLabel: "Apply",
                      onBtnCallback: _cache[12] || (_cache[12] = ($event: any) => (_ctx.getFilteredResults())),
                      onUpdateFilterValue: _cache[13] || (_cache[13] = 
                (filterValue) => (_ctx.selectedFilterAssignees = filterValue)
              )
                    }, null, 8, ["value", "options"])
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Clear",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (this.clearAssignedToFilter()))
                    })
                  ]),
                  filterapply: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Apply",
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.getFilteredResults()))
                    })
                  ]),
                  _: 1
                }, 8, ["sortable"]),
                (_ctx.isResultView)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      key: 2,
                      field: "amount",
                      header: "Amount",
                      sortable: _ctx.isResultView,
                      class: "font-semibold",
                      bodyStyle: "text-align: right; padding-right: 1rem"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(_ctx.formatCurrency(data.amount)), 1)
                      ]),
                      _: 1
                    }, 8, ["sortable"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_Column, {
                  field: "formatted_est_close",
                  header: "Est Close",
                  showAddButton: false,
                  showFilterMatchModes: true,
                  showFilterOperator: false,
                  sortable: _ctx.isResultView,
                  class: "font-semibold"
                }, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(data.est_close), 1)
                  ]),
                  filter: _withCtx(() => [
                    _createVNode(_component_Calendar, {
                      modelValue: _ctx.selectedFilterEstClose,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.selectedFilterEstClose) = $event)),
                      dateFormat: "mm-dd-yy",
                      class: "p-column-filter",
                      selectionMode: "range",
                      placeholder: "Select Date"
                    }, null, 8, ["modelValue"])
                  ]),
                  filterclear: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Clear",
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.clearDateFilter()))
                    })
                  ]),
                  filterapply: _withCtx(() => [
                    _createVNode(_component_Button, {
                      type: "button",
                      label: "Apply",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.getFilteredResults()))
                    })
                  ]),
                  _: 1
                }, 8, ["sortable"])
              ]),
              _: 1
            }, 8, ["value", "rows", "first", "selection", "sortField", "sortOrder", "onPage", "onSort", "onRowClick", "filters", "onFilter", "class"]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.isResultView && !_ctx.isLoadingSalesOpps)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_KanbanView, {
              lists: _ctx.formatedLists,
              section: "opps",
              loading: false,
              onOnClickEdit: _ctx.rowClick,
              onOnStatusChange: _ctx.onStatusChange
            }, null, 8, ["lists", "onOnClickEdit", "onOnStatusChange"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_SalesOppDialog, {
      show: this.showDialog,
      isEditing: this.isEditing,
      onHide: _cache[22] || (_cache[22] = ($event: any) => (_ctx.hideDialog(_ctx.hidden))),
      onOnSave: _cache[23] || (_cache[23] = ($event: any) => (this.fetchSaleOpps(false, true))),
      opportunity: this.saleOpp,
      opportunity_id: this.id
    }, null, 8, ["show", "isEditing", "opportunity", "opportunity_id"])
  ], 64))
}